import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import ArtworkBrowser from '../components/artworkBrowser';

export const query = graphql`{
  allPlayground(
    sort: {fields: openingDate, order: DESC}
    filter: {types: {in: ["Spielplatz"]}}
  ) {
    nodes {
      id
      title
      teaser
      slug
      openingDate
      location {
        city
        country
      }
    }
  }
  allFile(filter: {relativeDirectory: {nin: ["", "slides"]}, name: {in: "cover"}}) {
    nodes {
      id
      name
      relativeDirectory
      childImageSharp {
        gatsbyImageData(width: 300, height: 250, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
}
`;

const PlaygroundsPage = ({
  data: {
    allPlayground: { nodes: playgrounds },
    allFile: { nodes: images },
  },
}) => {
  return (
    <Layout>
      <Seo title="Spielplätze" />
      <Hero>
        <h1>Unsere Spielplätze</h1>
        <p className="lead">Außergewöhnliche Spielobjekte für Kids jeden Alters</p>
        <p>Ausgefallene Gestaltung und Qualität der Spielskulpturen (alles Unikate, keine Serienprodukte), langlebiges Holz wie Robinie und Eiche, alle Metallteile aus nicht rostendem Edelstahl und großes handwerkliches Können ist das Markenzeichen des Kollektivs.</p>
        <p>Selbstverständlich sind unsere Spielparks auf Sicherheit nach Norm EN 1176 zertifiziert.</p>
        <p>Durch unsere Vielseitigkeit können wir ein breit aufgestelltes, qualitativ hochwertiges Angebot für alle nötigen Arbeiten (auch Landschaftsbau) bieten.</p>
        <p>Ist mal eine Reparatur oder ein Austausch eines Teils nötig, stehen wir zur Verfügung und helfen auch nach Jahren gerne.</p>
      </Hero>

      <div className="container-xl">
        <h2 className="mb-4">Eine Auswahl unserer Spielplätze</h2>
        <ArtworkBrowser
          artworks={ playgrounds }
          images={ images }
          slug='spielplaetze'
        />
      </div>
   </Layout>
  );
};

export default PlaygroundsPage;